import {
  RecommendedH2,
  RecommendedInnerHeadingMain,
} from "@/StyledComponents/LandingStyled";
import {
  AIMLList,
  FormSectionCol,
  FormSectionMain,
} from "@/StyledComponents/TeamStyled";
import { Container, List } from "reactstrap";
import dynamic from "next/dynamic";
const ContactUsForm = dynamic(import("../Forms/ContactUsForm"), { ssr: false });
import OrangeLefrArrowSvg from "../svg/OrangeLefrArrowSvg";

export default function FormSection({ ipDetails }) {
  return (
    <Container className="pb-5">
      <FormSectionMain>
        <FormSectionCol>
          <RecommendedInnerHeadingMain>
            <RecommendedH2 className="formtitle">
              Have a project in mind? <br className="md:hidden block" /> Let's talk
            </RecommendedH2>
          </RecommendedInnerHeadingMain>
          <AIMLList className="form-list">
              <li className="!px-0">
                <div className="flex items-baseline gap-[10px]">
                  <div>
                    <OrangeLefrArrowSvg />
                  </div>
                  Hop on a call to lay out your goals and pain points.
                </div>
              </li>
              <li className="!px-0">
                <div className="flex items-baseline gap-[10px]">
                  <div>
                    <OrangeLefrArrowSvg />
                  </div>
                  We'll share relevant case studies from your industry vertical.
                </div>
              </li>
              <li className="!px-0">
                <div className="flex items-baseline gap-[10px]">
                  <div>
                    <OrangeLefrArrowSvg />
                  </div>
                  If we're a mutual fit, you'll receive a tailored proposal.
                </div>
              </li>
              <li className="!px-0">
                <div className="flex items-baseline gap-[10px]">
                  <div>
                    <OrangeLefrArrowSvg />
                  </div>
                  Then we'll kick things off with a 3-day trial run.
                </div>
              </li>
          </AIMLList>
        </FormSectionCol>
        <FormSectionCol>
          <ContactUsForm ipDetails={ipDetails} />
        </FormSectionCol>
      </FormSectionMain>
    </Container>
  );
}
